export type IconNameType = (typeof IconName)[keyof typeof IconName]

export const IconName = {
  arrowBoxLeft: 'arrow-box-left',
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  arrowUpRightFromCircleIcon: 'arrow-up-right',
  arrowOutOfBox: 'arrow-out-of-box',
  arrowsRepeat: 'arrows-repeat',
  book: 'book',
  bookmarkCheck: 'bookmark-check',
  brushSparkle: 'brush-sparkle',
  bubbleAnnotation: 'bubble-annotation',
  calendar: 'calendar',
  chainLink: 'chain-link',
  checkmark: 'checkmark',
  chevronDoubleLeft: 'chevron-double-left',
  chevronDoubleRight: 'chevron-double-right',
  chevronDownSmall: 'chevron-down-small',
  chevronGrabberVertical: 'chevron-grabber-vertical',
  chevronDownLarge: 'chevron-down-large',
  chevronLeft: 'chevron-left',
  chevronLeftSmall: 'chevron-left-small',
  chevronRight: 'chevron-right',
  chevronRightSmall: 'chevron-right-small',
  chevronTopSmall: 'chevron-top-small',
  circle: 'circle',
  circleArrow: 'circle-arrow',
  circleCheck: 'circle-check',
  circleDotsCenter: 'circle-dots-center',
  circlePlus: 'circle-plus',
  circleQuestionMark: 'circle-question-mark',
  circleX: 'circle-x',
  circlesThree: 'circles-three',
  copy: 'copy',
  crossLarge: 'cross-large',
  cryptoPunk: 'crypto-punk',
  crystalBall: 'crystal-ball',
  dotGrid: 'dot-grid',
  envelope: 'envelope',
  eyeOpen: 'eye-open',
  eyeSlash: 'eye-slash',
  fastForward: 'fast-forward',
  filter1: 'filter-1',
  filter2: 'filter-2',
  floppyDisk1: 'floppy-disk-1',
  floppyDisk2: 'floppy-disk-2',
  folder: 'folder',
  gift: 'gift',
  graduateCap: 'graduate-cap',
  group: 'group',
  headset: 'headset',
  layoutGrid: 'layout-grid',
  layoutThird: 'layout-third',
  lightningBolt: 'lightning-bolt',
  loader: 'loader',
  lock: 'lock',
  magnifyingGlass: 'magnifying-glass',
  medal: 'medal',
  megaphone: 'megaphone',
  microphone: 'microphone',
  mobile: 'mobile',
  moneybag: 'moneybag',
  personCircle: 'person-circle',
  pause: 'pause',
  play: 'play',
  playFilled: 'play-filled',
  playCircle: 'play-circle',
  plusLarge: 'plus-large',
  plusSmall: 'plus-small',
  rescueRing: 'rescue-ring',
  rocket: 'rocket',
  settingsGear: 'settings-gear',
  shieldCheck: 'shield-check',
  shieldCheckFilled: 'shield-check-filled',
  shoppingBag: 'shopping-bag',
  sparkle: 'sparkle',
  squareArrowTopRight: 'square-arrow-top-right',
  squareCheck: 'square-check',
  squareCheckEmpty: 'square-check-empty',
  squareChecklist: 'square-checklist',
  squarePlus: 'square-plus',
  squareX: 'square-x',
  trashCan: 'trash-can',
  volumeFull: 'volume-full',
  volumeMuted: 'volume-muted',
  wallet: 'wallet',
  wreath: 'wreath',
  fingerprint: 'fingerprint',
  x: 'x',
  discord: 'discord',
  lens: 'lens',
  farcaster: 'farcaster',
  calendly: 'calendly',
  medium: 'medium',
  github: 'github',
  inboxEmpty: 'inbox-empty',
  people: 'people',
  tag: 'tag',
  tagFilled: 'tag-filled',
  telegram: 'telegram',
  trustCircle: 'trust-circle',
  trustCircleFilled: 'trust-circle-filled',
  inProgress: 'in-progress',
  triangleExclamationFilled: 'triangle-exclamation-filled',
  triangleExclamation: 'triangle-exclamation',
  awaitAction: 'await-action',
  wallet2: 'wallet-2',
  chevronBottom: 'chevron-bottom',
  anchor: 'anchor',
  airdrop: 'airdrop',
  arrowCornerDownLeft: 'arrow-corner-down-left',
  ethereum: 'ethereum',
  map: 'map',
  crossSmall: 'cross-small',
  circleInfo: 'circle-info',
  circleCheckFilled: 'circle-check-filled',
  fingerprintFilled: 'fingerprint-filled',
  claimFilled: 'claim-filled',
  circlePlusFilled: 'circle-plus-filled',
  arrowDownCircleFilled: 'arrow-down-circle-filled',
  peopleAddFilled: 'people-add-filled',
  arrowUpCircleFilled: 'arrow-up-circle-filled',
  circleXFilled: 'circle-x-filled',
  peopleRemoveFilled: 'people-remove-filled',
  peopleAdd: 'people-add',
  peopleAdded: 'people-added',
  megaphone2: 'megaphone-2',
  bookmark: 'bookmark',
  bookmarkFilled: 'bookmark-filled',
  homeDoor: 'home-door',
  claim: 'claim',
  fileText: 'file-text',
  hamburger: 'hamburger',
  avatarSparkle: 'avatar-sparkle',
  twitter: 'twitter',
  mirror: 'mirror',
  warpcast: 'warpcast',
  galxe: 'galxe',
} as const
