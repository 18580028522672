const black = '#000000'
const white = '#FFFFFF'

export const palette = {
  black: {
    base: black,
    a90: `rgba(${black}, 90%)`,
    a80: `rgba(${black}, 80%)`,
    a70: `rgba(${black}, 70%)`,
    a60: `rgba(${black}, 60%)`,
    a50: `rgba(${black}, 50%)`,
    a40: `rgba(${black}, 40%)`,
    a30: `rgba(${black}, 30%)`,
    a20: `rgba(${black}, 20%)`,
    a10: `rgba(${black}, 10%)`,
    a5: `rgba(${black}, 5%)`,
    a3: `rgba(${black}, 3%)`,
  },
  white: {
    base: white,
    a90: `rgba(${white}, 90%)`,
    a80: `rgba(${white}, 80%)`,
    a70: `rgba(${white}, 70%)`,
    a60: `rgba(${white}, 60%)`,
    a50: `rgba(${white}, 50%)`,
    a40: `rgba(${white}, 40%)`,
    a30: `rgba(${white}, 30%)`,
    a20: `rgba(${white}, 20%)`,
    a10: `rgba(${white}, 10%)`,
    a5: `rgba(${white}, 5%)`,
    a3: `rgba(${white}, 3%)`,
  },
  gray: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E5E5E5',
    300: '#D4D4D4',
    400: '#A3A3A3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
    950: '#0A0A0A',
  },
  red: {
    50: '#FDF3F3',
    100: '#FBE5E5',
    200: '#F9CFCF',
    300: '#F3AEAE',
    400: '#EA807F',
    500: '#DD5352',
    600: '#CA3938',
    700: '#A92D2C',
    800: '#8C2928',
    900: '#752827',
    950: '#3F1010',
  },
  orange: {
    50: '#FFF4E6',
    100: '#FFDEB0',
    200: '#FFCE8A',
    300: '#FFB854',
    400: '#FFAA33',
    500: '#FF9500',
    600: '#E88800',
    700: '#B56A00',
    800: '#8C5200',
    900: '#6B3F00',
    950: '#553200',
  },
  yellow: {
    50: '#FFFAE6',
    100: '#FFEFB0',
    200: '#FFE88A',
    300: '#FFDD54',
    400: '#FFD633',
    500: '#FFCC00',
    600: '#E8BA00',
    700: '#B59100',
    800: '#8C7000',
    900: '#6B5600',
    950: '#5E4B00',
  },
  green: {
    50: '#F1FCF5',
    100: '#DFF9EB',
    200: '#C0F2D7',
    300: '#8FE6B8',
    400: '#57D191',
    500: '#34C578',
    600: '#23955A',
    700: '#1F754A',
    800: '#1D5E40',
    900: '#194D36',
    950: '#092A1D',
  },
  sky: {
    50: '#F2F9FE',
    100: '#E3F1FD',
    200: '#C3E5FA',
    300: '#91D1F8',
    400: '#61BAF3',
    500: '#4AA3E3',
    600: '#3882C2',
    700: '#2C679C',
    800: '#265881',
    900: '#21496B',
    950: '#142E47',
  },
  blue: {
    50: '#E6F2FF',
    100: '#B0D6FF',
    200: '#8AC2FF',
    300: '#54A6FF',
    400: '#3395FF',
    500: '#007AFF',
    600: '#006FE8',
    700: '#0057B5',
    800: '#00438C',
    900: '#00336B',
    950: '#042C5D',
  },
  purple: {
    50: '#F7EEFC',
    100: '#E6C9F5',
    200: '#DAAFF0',
    300: '#C98BE9',
    400: '#BF75E5',
    500: '#AF52DE',
    600: '#9F4BCA',
    700: '#7C3A9E',
    800: '#602D7A',
    900: '#4A225D',
    950: '#3B1B4A',
  },
}
